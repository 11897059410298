<template>
  <vue-ticket />
</template>

<script>

import Ticket from '@/components/Travel/Form.vue'

export default {
  name: 'ViewTicket',
  components: {
    'vue-ticket': Ticket,
  },
}
</script>
